.headerText {
  font-size: 22px;
  text-align: center;
  font-weight: 200;
  padding-top: 10px;
}

.max-width {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.plan-cards {
  display: flex;
  justify-content: center;
}
.plan-cards .Lite {
  order: 1 !important;
}
.plan-cards .Standard {
  order: 2 !important;
}
.plan-cards .Premium {
  order: 3 !important;
}
.plan-cards .Enterprise {
  order: 4 !important;
  border-right: none !important;
}
.plan-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plan-details-header {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  border-radius: 5px;
}

.plan-details-title {
  padding-top: 14px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  line-height: 18px;
  color: #f5786d;
}

.plan-details-sub-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  height: 18px;
  color: #262c2a;
  padding-top: 10px;
}

.plan-detail-price {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #1a1a92;
  padding-top: 36px;
}

.plan-details-price-sub-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.plan-details-text {
  padding-right: 35px;
  display: flex;
  justify-content: start;
}

.entrepreneur-use-to {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 12px;
}
.entrepreneur-use-to-item {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #161918;
  padding-left: 10px;
}

.best-valued {
  height: 24px;
  width: 105px;
  border-radius: 2px;
  background: #00b48a;
  margin-left: 35%;
  display: flex;
  text-align: center;
  margin-top: 50px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-bottom: 25px;
}

.section-select-plan-btn {
  display: flex;
  justify-content: center;
  padding: 0px 30px 0 0;
}

.select-plan-btn {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background-color: #f5786d;
  color: #fff;
  margin-top: 16px;
  bottom: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-plan-btn:hover,
.select-plan-btn:focus {
  border-color: #f5786d;
  background-color: transparent;
  color: #f5786d;
}
.bottomText {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 18px;
  font-weight: 500;
}

.bottomSpanText {
  font-size: 18px;
  font-weight: 700;
  color: #f5786d;
}

.use-title-section {
  padding: 0;
  margin-top: 30px;
}

.faq-title {
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  padding-bottom: 20px;
}

.faq-collapse {
  margin-bottom: 40px;
  background: white;
}
.faq-collapse .ant-collapse-header {
  border: 1px solid #bfbfbf;
  border-radius: 7px !important;
  font-weight: 500;
  color: #252525 !important;
  font-size: 17px;
}
.faq-collapse .ant-collapse-header img {
  height: 8px;
}
.faq-collapse-panel {
  margin-bottom: 20px;
  border-bottom: none !important;
  font-size: 19px;
  font-weight: 600;
}

.faq-collapse-panel-subtext {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.billing-tab {
  padding: 20px;
}

.billing-tab > .ant-row {
  gap: 15px;
}

.billing-tab > .ant-row:first-child {
  margin-bottom: 30px;
}

.billing-tab-card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #252525;
}

.billing-tab-card-body {
  background: #eff4f9;
  border-radius: 7px;
  padding: 20px;
  min-height: 191px;
  position: relative;
}

.billing-tab-current-plan-textcol {
  margin-left: 10px;
  margin-right: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.billing-tab-current-plan-textcol-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #252525;
}

.billing-tab-current-plan-btncol {
  justify-content: center;
  align-items: center;
}

.billing-tab-current-plan-btncol button {
  border: 1px solid #f5786d;
  border-radius: 7px;
  color: #f5786d;
  background: transparent;
  transition: 0.5s;
}

.billing-tab-current-plan-btncol button:hover,
.billing-tab-current-plan-btncol button:focus {
  border: 1px solid transparent;
  border-radius: 7px;
  color: white;
  background: #f5786d;
}

.billing-tab-touchpoint-second-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #124173;
}

.billing-tab-touchpoint-second-count {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  color: #252525;
}

.billing-tab-touchpoint-second-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252525;
}

.billing-tab-touchpoint-second-content {
  margin-left: 12px;
}

.billing-tab-touchpoint-first-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #f37064;
}

.billing-tab-touchpoint-first-count {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  color: #252525;
}

.billing-tab-touchpoint-first-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252525;
}

.billing-tab-touchpoint-first-content {
  margin-left: 12px;
}
.billing-tab-first-card-body {
  width: 100%;
}

.billing-tab-first-card-body > .ant-col {
  width: 50%;
}

.billing-tab-second-card-body {
  padding: 25px;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 20px;
}

.ant-menu-inline .ant-menu-item {
  height: 45px;
}

.trial-box {
  background: #eff4f9;
  border-radius: 7px;
  margin: 0 20px;
  padding: 20px;
  width: 95%;
}
.trial-box .img-wrapper {
  width: 10%;
}
.trial-box img {
  object-fit: contain;
  margin: auto;
}

.trial-box p {
  width: 90%;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
}
.thankyou-page {
  position: fixed;
  z-index: 1111;
  background-color: #fff;
  color: red;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thankyou-modal {
  background-color: #eff4f9;
  width: 512px;
  margin-left: calc(50% - 256px);
  border-radius: 20px;
}

.slider-tab .ant-slider-track {
  background: #124173;
  height: 8px;
  border-radius: 7px;
}
.slider-tab .ant-slider-rail {
  background: white;
  height: 8px;
  border-radius: 7px;
}
.slider-tab .ant-slider-handle {
  background: #124173;
  border: solid 2px #124173;
  height: 15px;
  margin-top: -4px;
}
.slider-tab .ant-slider-handle:focus {
  box-shadow: 2px #124173;
}
.slider-tab .ant-slider-step {
  margin-top: 2px;
}
.rs-slider-progress-bar {
  background-color: #124173 !important;
}

.ant-slider:hover .ant-slider-track {
  background-color: #124173 !important;
}
.slider-text .ant-divider-inner-text {
  padding: 0 0.3em !important;
}
.divider-billing .ant-divider-horizontal {
  margin: 0px !important;
}

.card-header .ant-card-head {
  background: #124173;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.card-header .ant-card-body {
  background: #eff4f9;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 14px;
}

.card-header .ant-radio-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-color: #124173;
}

.card-header .ant-radio-inner::after {
  top: 5px;
  left: 5px;
  color: #124173;
}

.radio-labels {
  padding: 0px 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.plan-details {
  margin-top: 20px;
  position: relative;
  left: -28px;
  width: 125% !important;
}

.select-plan-button {
  height: 39px;
  width: 161px !important;
  /* padding: 10px 40px; */
  background-color: #f5786d;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.my-plan-horizontal-divider {
  display: none !important;
}

.my-plan-vertical-divider {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.responsiveBilling {
  display: none;
}

.responsiveSlider {
  border-right: 2px solid #cddfef;
}

.switch-text {
  color: #7b95b0 !important;
  font-size: 16px;
}

.switchToggle {
  color: #124173 !important;
}

.ant-slider-tooltip {
  max-width: fit-content !important;
}

/* for billing plan slider */
.ant-slider-tooltip .ant-tooltip-inner {
  margin-top: 15px !important;
  background-color: #124173;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  height: 35px;
  padding: 9px 15px;
}

.ant-slider-tooltip .ant-tooltip-arrow-content {
  background-color: #124173;
  display: none;
}

.responsiveImg {
  height: 36px;
  width: 36px;
}
.background_image_container {
  position: absolute;
  height: 100%;
  width: 213px;
  right: 0px;
  bottom: 0px;
  background-image: url("./../../assets/Images/quarter-circles.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}

.overage-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  color: #eb1719;
}

.greeting-text {
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  color: #05a04c;
  display: inline !important;
}

.pro-plan-text {
  margin-top: 16px;
  display: inline-block;
  width: 158px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #2e2e2e;
  margin-bottom: 20px;
}

.invite-user-form .ant-form-item-label {
  padding: 0px;
}

.invite-user-form .ant-form-item-label label {
  font-size: 16px;
}

.contact-sales-text {
  font-family: "Hellix";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #959595;
  max-width: 220px;
  margin-top: 12px;
}
.billing-no-plan-container,
.billing-no-statistics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 30px 0px;
}

.billing-no-plan-text,
.billing-no-statistics-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #959595;
}

.setting-table .ant-tabs-tab-btn {
  font-size: 16px;
}

.edit_account_form .ant-form-item-label label {
  font-size: 16px !important;
}

.invite-user-multi-select .ant-select-selector,
.add-existing-contact-multi-select .ant-select-selector {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  min-height: 40px !important;
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
}

.antd-pen-icon svg {
  height: 17px;
  width: 17px;
}

/* --------------------- user tab ---------------------------- */

.setting-user-tab .ant-tabs-content-holder {
  border-left: none !important;
}

.setting-user-tab .ant-tabs-tab-active span {
  color: black !important;
}

.setting-user-tab .ant-tabs-tab {
  margin: 0px !important;
  justify-content: center !important;
}

.setting-user-tab .ant-input-group-addon {
  position: absolute;
  left: 0;
}
.setting-user-tab .ant-input-group-addon button {
  border: none !important;
  color: #959595 !important;
  background-color: transparent !important;
  z-index: 99;
}

.user-tab-content-wrapper {
  padding: 20px 0px 0px 13px !important;
}

.user-tab-content-wrapper .ant-tabs-nav {
  margin-top: 8px;
  height: fit-content;
}

.user-tab-content-wrapper .ant-tabs-nav-wrap {
  min-width: 218px !important;
  margin-bottom: 25px;
  border: 1px solid #d9eafb !important;
  border-radius: 7px;
}

.setting-user-tab .ant-tabs-nav-list {
  gap: 6px;
}

.setting-user-tab .ant-tabs-tab {
  background-color: white !important;
  border-radius: 7px !important;
  color: #252525 !important;
  border: none !important;
  padding: 15px 20px !important;
  justify-content: flex-start !important;
}

.setting-user-tab .tab-title-wrapper {
  display: flex;
  gap: 18px;
  align-items: center;
}

.setting-user-tab .tab-title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #252525;
}

.setting-user-tab .ant-tabs-tab-active {
  background-color: #eff4f9 !important;
}

.setting-user-tab .ant-tabs-extra-content {
  width: 100%;
}

.setting-user-tab .ant-tabs-tabpane {
  padding-left: 53px !important;
}

.user-request-title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #252525;
}

.user-request-main-wrapper {
  border-bottom: 1px solid #d9eafb;
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.user-request-main-wrapper:last-child {
  border: none;
}

.user-request-details-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-request-button-container {
  display: flex;
  gap: 8px;
}

.user-request-details-wrapper .user-icon {
  height: 20px;
  width: 20px;
}

.user-request-details-wrapper .user-request-email {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #303030;
  word-break: break-all;
}

.more-icon-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5px;
}

.more-icon-dot {
  width: 4px;
  height: 4px;
  background-color: #959595;
  border-radius: 50%;
}

/* --------------------- personal tab ---------------------------- */

.personal-details-form .ant-form-item-label {
  line-height: 1;
  padding-bottom: 6px;
}

.personal-details-form .ant-form-item-label label {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #252525;
}

.personal-details-form .ant-form-item {
  margin-bottom: 20px;
}

.invite-user-form #current_password,
.invite-user-form #confirm_new_password,
.invite-user-form #new_password {
  padding: 0px !important;
  font-size: 16px;
}

/* --------------------- manual billing placeholder ---------------------------- */

.manual-billing-placeholder-container {
  margin-top: 30px;
  background-color: #eff4f9;
  border-radius: 7px;
}

.manual-billing-title-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 18px 20px 0px;
}

.manual-billing-title {
  color: #252525;
  font-family: Hellix;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
}

.manual-billing-title-divider {
  display: block;
  height: 2px;
  background-color: #cddfef;
  margin: 0px;
}

.manual-billing-placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 50px 10px;
}

.manual-billing-placeholder-image {
  width: 120px;
  height: 91px;
}

.manual-billing-placeholder-content .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.manual-billing-placeholder-content .text-container .manual-billing-text1 {
  color: #252525;
  text-align: center;
  font-family: Hellix;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.manual-billing-placeholder-content .text-container .manual-billing-text2 {
  color: #69829b;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 387px;
}

.manual-billing-placeholder-content .text-container .manual-billing-text3 {
  color: #124173;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* --------------------- media queries ---------------------------- */

@media only screen and (max-width: 420px) {
  .manage-billing-button-container {
    width: 150px !important;
    padding: 5px 10px !important;
  }
  .manage-billing-button {
    font-size: 12px !important;
    line-height: 26px !important;
  }
}

@media only screen and (max-width: 576px) {
  .statistics-box {
    flex-direction: column;
  }
  .last-billing {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 992px) {
  .desktopBilling {
    display: none;
  }
  .responsiveBilling {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .plan-details-text {
    justify-content: center;
  }
  .plan-details-header {
    align-items: center;
  }
  .my-current-plan {
    flex-direction: column;
  }
  .my-plan-horizontal-divider {
    display: flex !important;
    width: 100%;
    margin: 20px 0px;
  }
  .my-plan-vertical-divider {
    display: none;
  }
  .responsiveSlider {
    border: none;
  }
  .plan-statistics-container {
    margin-bottom: 30px;
  }
  .background_image_container {
    height: 207px;
    border-top-right-radius: 0px;
  }
  .message-cost-text {
    justify-content: center;
    margin-bottom: 20px;
  }
  .greeting-text-container {
    justify-content: center;
  }
  .statistics-container {
    padding-left: 0px !important;
  }
  .setting-table .ant-tabs-nav-list .ant-tabs-tab {
    margin-left: 2px !important;
  }
}
