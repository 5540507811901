.contactBox {
  overflow-y: scroll;
  background: white;
  padding: 10px 20px;
  border-radius: 7px;
  position: relative;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0 15px;
}

.tableHeader .flexBox {
  display: flex;
  justify-content: right;
  align-items: center;
}

.tableHeader button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableHeader .ant-select-selector {
  width: 233px !important;
  height: 42px !important;
  font-size: 18px !important;
  line-height: 22px !important;
  border: 1px solid #252525 !important;
  border-radius: 7px !important;
  color: #252525 !important;
  align-items: center;
}

.tableHeader .ant-select-arrow {
  color: #252525 !important;
}

.tableHeader .flexBox .ant-input-affix-wrapper {
  width: 233px !important;
  height: 42px !important;
}

.products-origin-option {
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.tableHeader .flexBox .ant-input {
  font-size: 18px !important;
  line-height: 22px !important;
  color: #252525 !important;
}

.tableHeader .flexBox .ant-input::placeholder {
  font-size: 18px;
  line-height: 22px;
}

.contactBox .ant-table-thead {
  border-radius: 10px;
}
.contactBox th.ant-table-cell {
  background: #fff1f0 !important;
  border: none !important;
  font-weight: 600;
  letter-spacing: 0.75px;
}
.productBox th.ant-table-cell {
  background: #dfe7f0 !important;
}
.contactBox th.ant-table-cell::before {
  width: 0 !important;
  height: 0 !important;
}
.contactBox .ant-table-tbody > tr > td {
  border-right: 1px solid #f0f0f0;
}

.contactBox .ant-table-tbody > tr > td:nth-child(1) {
  border-left: 1px solid rgb(239, 244, 249) !important;
}

.border .ant-table-tbody > tr > td:nth-child(7) {
  border-right: 1px solid rgb(239, 244, 249) !important;
}

.contactBox table a {
  color: #252525;
}

.contactBox .button span {
  color: #959595;
}

.contactBox .manageButton {
  color: #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding-left: 0px !important;
}

.contactBox .ant-table-footer {
  position: absolute;
  background-color: transparent;
  width: 100%;
}
.contactBox .tableFooter {
  position: absolute;
  background-color: transparent;
  right: 20px;
  top: 15px;
}
.contactBox .tableFooter h4 {
  color: #959595;
}
.contactBox .manageButton .anticon-setting {
  margin-top: 4px;
  border: none;
}
.contactBox .ant-btn-dangerous {
  color: #f5786d;
  border-color: #f5786d;
}
.contactBox .ant-pagination-item {
  border-color: transparent !important;
}
.contactBox .ant-pagination-item-active a {
  color: #f5786d;
}
.noDataScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.detailPage {
  width: 90%;
  margin: 0 auto;
}
.detailPage .ant-page-header {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.detailPage .ant-page-header span,
.detailPage .ant-page-header span {
  color: #959595;
  font-size: 15px;
  font-weight: 500;
}
.formBox {
  background: white;
  border-radius: 7px;
  padding: 15px 0;
}
.productUsage {
  margin-top: 30px;
}
.timelineTitle {
  display: flex;
}
.timelineTitle h3 {
  color: #124173;
}
.timelineTitle span {
  color: #959595;
  padding-left: 5px;
  margin-top: 5px;
  font-size: 13px;
}
.productUsage .ant-timeline-item-tail {
  border-left-color: #cde1f3;
}
.productUsage .ant-timeline-item-head {
  background: #cde1f3;
}

.manageProperty .detailPage {
  width: 100%;
}
.manageProperty .ant-page-header-heading-extra > * {
  margin-left: 0;
}
.manageProperty .ant-page-header .ant-divider-vertical {
  height: 28px;
  margin: 0;
}
.manageProperty .title {
  width: 30%;
}
.manageProperty .inputType {
  width: 60%;
}
.manageProperty tr td {
  padding: 5px 10px;
}
.addProperty .ant-drawer-header {
  background: #eff4f9;
}
.addProperty .ant-drawer-header button {
  color: #a9a9a9;
}

.addProperty .ant-modal-close {
  top: -10px;
  right: -10px;
}

.detailPage .ant-avatar,
.detailPage .ant-upload {
  position: relative;
}
.detailPage .ant-upload .profileEdit {
  position: absolute;
  bottom: -3px;
  right: 0;
  z-index: 99;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #959595;
  width: 21px;
  height: 21px;
  min-width: 21px;
}
.detailPage .ant-upload .profileEdit span {
  font-size: 10px;
}
.detailPage .ant-upload .nameEdit span {
  font-size: 10px;
}
.detailPage .selectTab .ant-select-selector {
  border: none !important;
  color: #f5786d !important;
  font-weight: 600;
}
.detailPage .selectTab .ant-select-arrow {
  color: #f5786d !important;
  font-weight: 600;
}

.csv-modal {
  padding-bottom: 0 !important;
}
.csv-modal .ant-row {
  margin: 0 !important;
}
.csv-modal .ant-modal-body {
  padding: 0 !important;
}
.csv-modal .sampleCsv {
  border-radius: 10px 0 0 10px;
  padding: 80px 27px !important;
}
.csv-modal .sampleCsv p {
  margin: 10px 0;
  font-size: 16px;
}
.csv-modal .drag-file {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.csv-modal .drag-file .ant-upload.ant-upload-drag {
  width: 250px !important;
  height: 200px !important;
  background-color: transparent !important;
  margin: auto;
}
.csv-modal .drag-file .ant-upload-text {
  width: 130px;
  margin: auto !important;
  color: #959595 !important;
}

.attributeDrawer .ant-drawer-header {
  background-color: #252525 !important;
}
.attributeDrawer .ant-drawer-body {
  background: #e2ebf3;
}
.attributeDrawer .ant-drawer-close {
  color: #f5786d !important;
  padding: 20px 32px 10px 20px !important;
}
.attributeDrawer .ant-select-selector {
  background: transparent !important;
  border: none !important;
  color: white;
  padding: 0 !important;
}
.attributeDrawer .ant-select-arrow {
  color: white !important;
}
.attributeDrawer .attributes {
  display: flex;
  flex-direction: column;
}
.attributeDrawer .attributes button {
  width: 100%;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  height: 50px;
  display: flex;
  align-items: center;
}
.attributeDrawer .attributes button h3 {
  margin-bottom: 0;
  color: #124173;
  font-weight: 600;
}

.productAttributeForm .ant-form-item-label {
  width: 50%;
  text-align: left;
}
.select-journey .ant-space {
  width: 100%;
}
.select-journey .ant-radio-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.select-journey .ant-radio-wrapper span:nth-child(2) {
  width: calc(100% - 5.8%);
}
.select-journey .ant-radio-wrapper .ant-radio {
  width: 5.8%;
}
.addProperty .ant-radio-wrapper .ant-radio {
  width: 4.5%;
}
.addProperty .ant-radio-group-outline .ant-space-item {
  margin: 5px 0;
}

.productTable .ant-table-row .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productTable .wrapper .buttonWrapper {
  display: flex;
  flex-direction: column;
}

.productTable .ant-table-cell {
  padding: 10px 5px;
}
.productTable .ant-table-wrapper {
  overflow-x: scroll !important;
}
.product-table-cell {
  border: none;
  outline: none;
}

.productTable .ant-pagination-options {
  display: none !important;
}

.product-container {
  width: 48%;
}

.chatbox {
  position: relative;
  height: calc(100vh - 112px);
  max-height: calc(100vh - 112px);
  width: 100%;
  overflow-y: scroll !important;
}

.product-detail-modal {
  width: 50% !important;
}

.product-detail-modal-button {
  border-radius: 7px;
}

.product-detail-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.chat-messages-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: scroll !important;
}

.chat-message {
  display: flex;
  width: fit-content;
  max-width: 70%;
  border: 1px solid gray;
  padding: 5px 10px;
  background-color: #124173;
  color: white;
  border-radius: 7px;
}

.user-chat-message,
.bot-chat-message {
  display: flex;
  width: 100%;
  padding: 5px 0px;
  gap: 5px;
}

.user-chat-message {
  justify-content: flex-end;
}

.chatbox-form {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  position: sticky;
  bottom: 0px;
  width: 100%;
  background-color: #eff4fa;
  padding: 0px 10px;
}

.input-container {
  width: 100%;
  margin-top: 24px;
}

.message-input {
  width: 100%;
}

.chatbox-form-button {
  border-radius: 7px;
}

.chatbox-form .clear-button {
  background-color: #124173 !important;
  border: none;
}

.product-button {
  background-color: #124173 !important;
  height: 50px;
}

.prouct-button-float {
  animation: float 3s ease-in-out infinite;
}

.product-details-samples-dropdown {
  width: 50%;
}

.profile-picture {
  height: 34px;
  width: 34px;
  border-radius: 50%;
}

.vertical-dots svg {
  font-size: 32px !important;
  width: 2rem !important;
  height: 2rem !important;
}

.popover-text-container {
  padding: 15px;
}

.popover-text {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
  cursor: pointer;
}

.quick-map-buttons-container {
  margin-top: 10px;
  display: flex;
  gap: 12px;
}

.quick-map-now-button {
  width: 124px;
  background: #3b84d1;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 22px;
  height: 46px;
}

.quick-map-cancel-button {
  width: 106px;
  color: #959595;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 22px;
  height: 46px;
}

.integration-platforms {
  width: 70px !important;
}

.integration-platforms .ant-select-selector {
  height: 42px !important;
  border: none !important;
  border-radius: 7px !important;
  align-items: center;
  background-color: #eff4f9 !important;
}

.integration-platforms .ant-select-arrow {
  color: #252525 !important;
}

.platform-type-option img {
  height: 27px !important;
  width: 27px !important;
}

.contact-detail-page-container,
.product-detail-page-container {
  margin-top: 10px;
}

.contact-detail-content-wrapper,
.product-detail-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-name-container,
.product-name-container {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 15px 20px 17px;
  gap: 12px;
  border-radius: 7px;
}

.contact-name-container .contact-icon {
  color: #f37064;
  font-size: 28px;
}

.contact-name-container .contact-name {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #f37064;
  margin-bottom: 0px;
}

.contact-detail-content-wrapper .contact-attributes-container,
.contact-detail-content-wrapper .contact-product-attributes-container,
.product-detail-content-wrapper
  .product-details-contact-product-attributes-container {
  background-color: white;
  padding: 14px 20px 16px;
  border-radius: 7px;
}

.contact-attributes-container .attributes-title-container,
.contact-product-attributes-container .attributes-title-container,
.product-details-contact-product-attributes-container
  .attributes-title-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 22px;
}

.attributes-title-container .title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
  margin-bottom: 0px;
}

.contact-product-attribute-form {
  min-height: 400px;
}

.contact-attribute-form .ant-form-item-label,
.contact-product-attribute-form .ant-form-item-label {
  padding-bottom: 4px;
}

.contact-attribute-form .ant-form-item-label label,
.contact-product-attribute-form .ant-form-item-label label {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #252525;
}

.contact-attribute-form-item,
.contact-attribute-form-item .ant-skeleton {
  width: 100%;
}

.initial-skeleton-loader {
  width: 100%;
  height: 40px !important;
  margin: 0px;
  border-radius: 8px;
}

.contact-attribute-form-item .input-skeleton-loader {
  height: 40px;
  margin: 0px;
  border-radius: 8px;
}

.view-more-attribute-button {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #f37064;
  cursor: pointer;
}

.contact-product-attributes-container .search-button-container,
.product-details-contact-product-attributes-container .search-button-container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.contact-product-attribute-pagination-container {
  margin-top: 13px !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 18px 20px;
  border: 1px solid #e2ebf3;
  border-radius: 11px;
}

.contact-product-attribute-pagination .ant-pagination-item {
  border: none !important;
}

.contact-product-attribute-pagination-container .attribute-text {
  margin-bottom: 0px;
  font-family: "Hellix";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #959595;
}

.products-contact-and-search-wrapper {
  background: #eff4f9;
  border-radius: 7px;
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  max-height: 400px;
}

.product-details-contact-product-attributes-container
  .products-contact-container {
  background: #eff4f9;
  border-radius: 7px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 350px;
  overflow-y: scroll;
}

.products-contact-searchbox {
  padding: 16px 16px 0px 16px;
}

.products-contact-searchbox .ant-input-affix-wrapper,
.products-contact-searchbox .ant-input-affix-wrapper .ant-input {
  background-color: #eff4f9;
}

.contact-product-attributes-container .contacts-product-container {
  background: #eff4f9;
  border-radius: 7px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow-y: scroll;
}

.contacts-product-container .contacts-product-detail-container,
.products-contact-container .contacts-product-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
}

.contacts-product-detail-container .product-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 90%;
}

/* .product-wrapper .product-image {
  height: 25px;
  width: 25px;
  border-radius: 50%;
} */

.product-wrapper .product-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6e5f4;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-bottom: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3b84d1;
}

.product-wrapper .product-name {
  margin-bottom: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #252525;
  width: calc(100% - 33px);
}

.contacts-product-detail-container .delete-icon-container {
  cursor: pointer;
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.delete-contacts-product-modal,
.delete-contacts-product-modal-button-wrapper {
  display: flex;
  gap: 17px;
  margin-top: 17px;
}

.delete-contacts-product-modal .delete-content-container {
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts-product-delete-modal-dark-text {
  margin-top: 17px;
  max-width: 270px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #252525;
  margin-bottom: 0px;
}

.contacts-product-delete-modal-light-text {
  margin-top: 8px;
  max-width: 379px;
  font-family: "Hellix";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #686868;
  margin-bottom: 0px;
}

.contacts-table .ant-table-body {
  min-height: 325px !important;
  height: fit-content !important;
  max-height: calc(100vh - 480px) !important;
}

.contacts-product-container .highlight-product,
.products-contact-container .highlight-product {
  color: #3b84d1;
}

.contacts-product-container .highlighted-bar,
.products-contact-container .highlighted-bar {
  position: absolute;
  right: -16px;
  width: 4px;
  height: 23px;
  background: #3b84d1;
  border-radius: 20px;
}

.product-attribute-form .ant-form-item-label label,
.add-contact-form .ant-form-item-label label,
.tritech-mapping-modal .ant-form-item-label label {
  font-size: 16px;
}

.existing-contact-select .ant-select-selector {
  padding: 0px !important;
}

@media only screen and (max-width: 992px) {
  .product-details-samples-dropdown {
    width: 100%;
  }
}

@media only screen and (max-width: 780px) {
  .product-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 567px) {
  .detailPage .product-container,
  .detailPage .w-100 {
    display: block !important;
  }
  .product-detail-modal {
    width: 75% !important;
  }
}

@media only screen and (max-width: 420px) {
  .product-detail-modal {
    width: 90% !important;
  }
}

.contacts-product-detail-container {
  gap: 8px;
  display: flex;
}
.contact-model .ant-modal-content {
  width: 400px !important;
  margin: auto;
}
.contact-model-text .ant-modal-body {
  line-height: 1 !important;
}

.product-name-container .product-name {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #3b84d1;
  margin-bottom: 0px;
}

.csv-modal .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
