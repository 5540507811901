.ant-menu .ant-menu-dark {
  background-color: #124173 !important;
}
.site-layout-background {
  background-color: #fff !important;
  min-height: 64px !important;
  height: auto !important;
}
#blustream-container #site-layout {
  margin-left: 250px;
  z-index: 1;
  transition: 0.2s;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.quicksight {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60%;
}
.ant-popover-buttons {
  padding-right: 10px;
}

.dashboard-loader {
  background-color: #fff;
  padding: 25px;
}
.dashboard-chart-skeleton {
  width: 24vw !important;
  height: 200px !important;
}
.layout {
  margin-left: 80px !important;
}
.layoutCollpsed {
  margin-left: 250px !important;
}

.ant-popover-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.ant-popover-message {
  padding-bottom: 0px !important;
}

.ant-popover-message-title {
  padding: 0px !important;
}

.ant-popover-message-title h5 {
  padding: 10px 16px 0px !important;
}

.welcome-to-blustream-modal {
  width: max-content !important;
}

.welcome-to-blustream-modal .ant-modal-body {
  padding: 40px 30px 30px;
}

.welcome-to-blustream-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
}

.welcome-to-blustream-modal-content .welcome-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #252525;
}

.welcome-to-blustream-modal-content .welcome-text-2 {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #252525;
  max-width: 330px;
}

.header-banner-container {
  display: flex;
  justify-content: center;
  width: calc(100% - 630px);
}

.account-status-banner {
  line-height: 16px !important;
  font-size: 16px;
  font-weight: 600;
  color: #f37064;
  background-color: #ffe9e9;
}

.show-more-button {
  line-height: 16px !important;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #f37064;
  min-width: 95px;
  background-color: #ffe9e9;
  text-decoration: underline;
}

.header-container .ant-tooltip-arrow-content {
  background-color: #ffffff;
}

.header-container .ant-tooltip-inner {
  background-color: #ffffff;
  color: #252525;
}

.profile-badge .ant-badge-dot {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #3b84d1;
  height: 10px;
  width: 10px;
}

.invitation-modal,
.decline-invite-modal {
  width: fit-content !important;
}

.invitation-modal .ant-modal-body,
.decline-invite-modal .ant-modal-body {
  display: flex;
  max-width: 469px;
  padding: 30px 44px;
}

.invitation-modal .ant-modal-close-icon,
.decline-invite-modal .ant-modal-close-icon {
  position: absolute;
  bottom: 40px;
  left: 65px;
  font-size: 17px;
  color: white;
}

.invitation-modal-content-wrapper,
.decline-invite-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invitation-modal-content-wrapper .invitation-title,
.decline-invite-modal-content-wrapper .decline-invite-confirmation-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #303030;
  margin: 18px 0px 10px 0px;
}

.decline-invite-modal-content-wrapper .decline-invite-confirmation-text {
  margin: 20px 0px;
}

.decline-invite-modal-content-wrapper .decline-invite-note {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #686868;
  margin-bottom: 20px;
}

.invitation-modal-content-wrapper .company-name {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #3b84d1;
  margin-bottom: 14px;
}

.invitation-modal-content-wrapper .members-name {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #6686a6;
  margin-bottom: 20px;
}

.invitation-modal-content-wrapper .invitation-buttons-container,
.decline-invite-modal .decline-invite-buttons-container {
  display: flex;
  gap: 20px;
}

.invitation-menu {
  padding: 11px 12px !important;
  margin: 0px !important;
  line-height: 1 !important;
  height: fit-content !important;
  background-color: #f7f7f7 !important;
}

.invitation-menu .invite-decline-container {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}

.invitation-menu .invitation-text {
  white-space: pre-line;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #252525;
  margin-bottom: 10px;
  word-break: break-all;
}

.invitation-menu .menu-invite-buttons-container {
  display: flex;
  justify-content: space-between;
}

.user-menu {
  padding: 10px 12px !important;
  margin: 0px !important;
  line-height: 1 !important;
  height: fit-content !important;
}

.user-menu-item {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
}

.oraganization-title {
  padding: 5px 12px !important;
  margin: 0px !important;
  line-height: 1 !important;
  height: fit-content !important;
  background-color: #f7f7f7 !important;
  pointer-events: none;
}

.org-title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #959595;
}

.logout-menu {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #e24b4c;
}

.user-menu-container .ant-menu-item-selected {
  background-color: white !important;
}
.user-menu-container .ant-menu-item a:hover,
.user-menu-container .ant-menu-item-selected a,
.user-menu-container .ant-menu-item-selected a:hover {
  color: #124172 !important;
}
